import { CircularProgress, useTheme } from "@mui/joy";
import { Box, CssBaseline, Grid, useMediaQuery } from "@mui/material";
import {
  useGetCurrentUserQuery,
  useSignOutMutation,
} from "@nerdjs/account-kit";
import { initNetworkAction } from "@nerdjs/nerd-network";
import { NerdNotifier, clearNotification } from "@nerdjs/nerd-ui";
import { ReactElement, useEffect } from "react";
import { AppRouter } from "../../appRouter";
import { AppConfig } from "../../environement";
import { RouterConfig } from "../config/routerConfig";
import { useAppDispatch, useAppSelector } from "../hooks";

/**
 * App wrapper
 *
 * @returns {ReactElement} App wrapper
 */
export default function NerdApp(): ReactElement {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down("md"), {
    noSsr: true,
  });
  const { isSuccess } = useSignOutMutation()[1];

  useEffect(() => {
    dispatch(initNetworkAction(AppConfig.api));
  }, []);

  const { isFetching } = useGetCurrentUserQuery();

  useEffect(() => {
    //If signout success, refresh window to reset redux state
    if (isSuccess) {
      window.location.replace(RouterConfig.root);
    }
  }, [isSuccess]);

  return (
    <NotifierWrapper>
      <Box
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: md ? "column" : "row",
        }}
      >
        <CssBaseline />
        {isFetching ? (
          <Grid justifyContent={"center"} alignItems={"center"} container>
            <Grid item>
              <CircularProgress />
            </Grid>
          </Grid>
        ) : (
          <>
            <Box
              component="main"
              sx={{
                flexGrow: 1,
                display: "flex",
              }}
            >
              <AppRouter />
            </Box>
          </>
        )}
      </Box>
    </NotifierWrapper>
  );
}

const NotifierWrapper = ({ children }: { children: ReactElement }) => {
  const dispatch = useAppDispatch();
  const notification = useAppSelector((s) => s.notification);
  return (
    notification && (
      <NerdNotifier
        {...notification}
        onClose={() => {
          dispatch(clearNotification());
          notification.onClose?.();
        }}
      >
        {children}
      </NerdNotifier>
    )
  );
};
