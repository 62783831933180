import { Box, Typography } from "@mui/joy";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { Paper } from "../atoms/paper";
import { useMissionMap } from "../templates/answerTemplate";
import { AppConfig } from "../environement";

export function ProjectRosters({ token }: { token: string }) {
  const mission = useMissionMap(token);
  const apiEndpoint = AppConfig.api.endpoint;
  let endpoint = "live";
  if (apiEndpoint.includes("alpha")) endpoint = "alpha";
  if (apiEndpoint.includes("demo")) endpoint = "demo";

  return (
    <Paper>
      <Box>
        <Typography level="title-lg">Rosters</Typography>
        <Typography level="body-sm">
          Click on the line to download the document
        </Typography>
      </Box>
      <DataGridPro
        disableColumnFilter
        disableColumnMenu
        getRowId={(r) => `${r.URL}`}
        sx={{ flex: 1, width: "100%", custor: "pointer" }}
        density="compact"
        hideFooter
        autoHeight
        columns={[
          {
            field: "Name",
            headerName: "Name",
            flex: 1,
            renderCell: (p) => (
              <Typography
                level="body-xs"
                sx={{ color: "#2196f3", fontWeight: "bold", cursor: "pointer" }}
              >
                <u>{p.row.Name}</u>
              </Typography>
            ),
          },
        ]}
        onRowClick={(r) => window.open(`${r.row.URL}`)}
        rows={mission?.ProjectRosters ?? []}
      />
    </Paper>
  );
}
