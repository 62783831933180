import { Avatar, Box, Typography } from "@mui/joy";
import { useMissionMap } from "../templates/answerTemplate";

/**
 *
 * @returns {ReactElement} PersonMessage page
 */
export function Memo({
  memo,
  compact,
  warning,
  token,
}: {
  memo: string;
  compact?: boolean;
  warning?: boolean;
  token: string;
}) {
  const missionMap = useMissionMap(token);

  if (!missionMap) return <Box />;

  if (memo === "~missing~") return <Box />;

  return (
    <Box
      sx={{
        display: "flex",
        gap: 1,
        alignItems: "start",
        textAlign: "start",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar size={compact ? "sm" : "md"} src={missionMap.MessengerAvatar} />
        {!compact && (
          <Typography level="body-xs">{missionMap.ContractorName}</Typography>
        )}
      </Box>
      <Box
        sx={{
          p: 0.5,
          borderRadius: "8px",
          background: "#9e9e9e33",
          mt: !compact ? 1 : 0.1,
        }}
      >
        <Typography
          sx={{ color: "inherit" }}
          level={compact ? "body-xs" : "body-sm"}
        >
          {memo}
        </Typography>
      </Box>
    </Box>
  );
}
