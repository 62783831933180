import { Box } from "@mui/system";
import React, { ReactElement, createContext, useContext } from "react";
import Confetti from "react-confetti";

export const ConfettiCenterContext = createContext({
  show: () => console.log("Snackbar not defined"),
});

export default function ConfettiCenter({
  children,
}: {
  children: ReactElement;
}) {
  const [count, setCount] = React.useState(0);
  const show = () => {
    setCount((e) => e + 1);
  };

  return (
    <ConfettiCenterContext.Provider value={{ show }}>
      {children}
      {count > 0 ? (
        <Box sx={{ zIndex: 1302, top: 0, left: 0, position: "fixed" }}>
          <Confetti
            key={count}
            recycle={false}
            gravity={0.2}
            tweenDuration={4000}
            numberOfPieces={200}
          />
        </Box>
      ) : (
        []
      )}
    </ConfettiCenterContext.Provider>
  );
}

export const useConfettiCenter = () => {
  const context = useContext(ConfettiCenterContext);
  return context;
};
