import dayGridPlugin from "@fullcalendar/daygrid";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import {
  Box,
  Button,
  IconButton,
  Modal,
  ModalClose,
  ModalDialog,
  Tab,
  TabList,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/joy";
import moment from "moment";
import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Paper } from "../atoms/paper";
import { MissionMap_Entity } from "../entities/missionMap";
import { Engagement, Piece } from "../entities/missionMap/missionMap";
import { EngagementLineItem } from "../molecules/engagementLineItem";
import { setSelectedToken } from "../redux/rhapsodyReducer";

const colors = [
  "#2196f3",
  "#4caf50",
  "#ffc107",
  "#9c27b0",
  "#4caf50",
  "#cddc39",
];

export function SeasonCalendar({
  missions,
}: {
  missions: MissionMap_Entity[];
}) {
  const dispatch = useDispatch();
  const calendarRef = useRef();
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedEngagement, setSelectedEngagement] = useState<
    Engagement & { Token: string }
  >();
  const [expand, setExpand] = useState(false);
  const tzName = moment.tz.guess();

  const events = missions.reduce<(Engagement & { Token: string })[]>((a, v) => {
    v.Engagements.forEach((e) => {
      e.Content.forEach((c) => {
        a.push({ ...c, Token: v.PublicToken });
      });
    });
    return a;
  }, []);

  const getEvents = () => {
    const ret: any[] = [];
    events.forEach((e) => {
      const index =
        (missions.findIndex((m) => m.PublicToken === e.Token) % colors.length) -
        1;

      ret.push({
        id: e.ID,
        title: `${e.Title}. ${e.VenueName}`,
        start: e.DateFromUTC,
        end: e.DateToUTC,
        color: e.Playing ? `${colors[index]}` : `${colors[index]}44`,
        allDay: false,
        editable: false,
        eventStartEditable: false,
        event: e,
        token: e.Token,
      });
    });

    return ret;
  };

  function getSelectedDate() {
    const calendarEl: any = calendarRef.current;
    return calendarEl?.getApi().view.title;
  }

  function getInitialDate() {
    const upcomings = events.filter((e) =>
      moment(e.DateFromUTC).isAfter(moment())
    );
    if (upcomings.length > 0) {
      return moment(upcomings[0].DateFromUTC).toDate();
    }

    events.sort(
      (a, b) =>
        moment(a.DateFromUTC).valueOf() - moment(b.DateFromUTC).valueOf()
    );

    if (events.length > 0) {
      return moment(events[0].DateFromUTC).toDate();
    }

    return moment().toDate();
  }

  const handleEventClick = (clickInfo: any) => {
    const e = clickInfo.event;
    const event = events.find((w) => w.ID === parseInt(e.id));
    setSelectedEngagement(event);
  };

  const calendar = (
    <Box>
      <Typography level="title-lg">Calendar</Typography>
      <Typography level="body-sm">{getSelectedDate()}</Typography>
      <Typography level="body-xs">{tzName}</Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex", gap: 1 }}>
          <Button
            onClick={() => {
              const calendarEl: any = calendarRef.current;
              calendarEl?.getApi()?.today();
              setSelectedDate(getSelectedDate());
            }}
            size="sm"
            color="neutral"
            variant="soft"
          >
            Today
          </Button>
          <IconButton
            onClick={() => {
              const calendarEl: any = calendarRef.current;
              calendarEl?.getApi()?.prev();
              setSelectedDate(getSelectedDate());
            }}
            size="sm"
            color="neutral"
            variant="soft"
          >
            <i className="fa-regular fa-chevron-left"></i>
          </IconButton>
          <IconButton
            onClick={() => {
              const calendarEl: any = calendarRef.current;
              calendarEl?.getApi()?.next();
              setSelectedDate(getSelectedDate());
            }}
            size="sm"
            color="neutral"
            variant="soft"
          >
            <i className="fa-regular fa-chevron-right"></i>
          </IconButton>
        </Box>
        <Tabs
          defaultValue={"week"}
          onChange={(e, v) => {
            const calendarEl: any = calendarRef.current;
            switch (v) {
              case "month":
                calendarEl?.getApi()?.changeView("dayGridMonth");
                setSelectedDate(getSelectedDate());
                break;
              case "week":
                calendarEl?.getApi()?.changeView("timeGridWeek");
                setSelectedDate(getSelectedDate());
                break;
            }
          }}
          size="sm"
          variant="soft"
          sx={{ borderRadius: 3 }}
        >
          <TabList variant="outlined" color="neutral">
            <Tab value="month">Month</Tab>
            <Tab value="week">Week</Tab>
          </TabList>
        </Tabs>
      </Box>
      <FullCalendar
        height={expand ? "calc(100vh - 128px)" : "480px"}
        plugins={[dayGridPlugin, timeGridPlugin]}
        headerToolbar={{
          left: "",
          center: "",
          right: "",
        }}
        // datesSet={(e) => {
        //   if (!today) dispatch(setCalendarOptions(e));
        // }}
        ref={calendarRef as any}
        weekNumbers
        initialDate={getInitialDate()}
        initialView={"timeGridWeek"}
        // firstDay={firstDay}
        // selectable={
        //   !musicianID && !venueID && !customerID && !payrollID && !readOnly
        // }
        selectMirror
        dayMaxEvents
        allDaySlot={false}
        weekends
        events={getEvents()}
        // select={!readOnly ? handleDateSelect : undefined}
        // eventContent={(e) => <RenderEventContent eventInfo={e} />}
        eventClick={handleEventClick}
        eventDurationEditable={false}
      />
    </Box>
  );

  return (
    <Paper sx={{ alignItems: "stretch", position: "relative" }}>
      <Tooltip title="Expand" arrow>
        <IconButton
          onClick={() => setExpand(true)}
          size="sm"
          sx={{ position: "absolute", top: 1, right: 1 }}
        >
          <i className="fa-solid fa-arrow-up-right-and-arrow-down-left-from-center"></i>
        </IconButton>
      </Tooltip>
      {expand ? (
        <Modal sx={{ zIndex: 100000 }} open onClose={() => setExpand(false)}>
          <ModalDialog layout="fullscreen">
            <ModalClose />
            <Typography>{calendar}</Typography>
          </ModalDialog>
        </Modal>
      ) : (
        calendar
      )}
      {selectedEngagement ? (
        <Modal
          sx={{ zIndex: 100001 }}
          open
          onClose={() => setSelectedEngagement(undefined)}
        >
          <ModalDialog>
            <EngagementLineItem
              projectInfo
              engagement={selectedEngagement}
              token={selectedEngagement.Token}
              openProject={() => {
                dispatch(setSelectedToken(selectedEngagement.Token));
                setSelectedEngagement(undefined);
              }}
            />
          </ModalDialog>
        </Modal>
      ) : (
        []
      )}
    </Paper>
  );
}
