import { Box, Typography } from "@mui/joy";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { Paper } from "../atoms/paper";
import { useMissionMap } from "../templates/answerTemplate";

export function Links({ token }: { token: string }) {
  const mission = useMissionMap(token);

  console.log(mission?.Links);

  return (
    <Paper>
      <Box>
        <Typography level="title-lg">Links</Typography>
        <Typography level="body-sm">
          Click on the line to download the document
        </Typography>
      </Box>
      <DataGridPro
        disableColumnFilter
        disableColumnMenu
        getRowId={(r) => `${r.URL} - ${r.Description} - ${r.Name}`}
        sx={{ flex: 1, width: "100%", custor: "pointer" }}
        density="compact"
        hideFooter
        autoHeight
        columns={[
          {
            field: "Name",
            headerName: "Name",
            renderCell: (p) => (
              <Typography
                level="body-xs"
                sx={{ color: "#2196f3", fontWeight: "bold", cursor: "pointer" }}
              >
                <u>{p.row.Name}</u>
              </Typography>
            ),
          },
          {
            field: "Description",
            headerName: "Description",
            flex: 1,
            renderCell: (p) => (
              <Typography
                level="body-xs"
                sx={{ color: "#2196f3", fontWeight: "bold", cursor: "pointer" }}
              >
                <u>{p.row.Description}</u>
              </Typography>
            ),
          },
        ]}
        onRowClick={(r) =>
          window.open(
            r.row.URL.indexOf("http") === -1
              ? `https://${r.row.URL}`
              : r.row.URL
          )
        }
        rows={mission?.Links ?? []}
      />
    </Paper>
  );
}
