import { Box, Button, Checkbox, Divider, Textarea, Typography } from "@mui/joy";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useSelector } from "react-redux";
import { formOpenSelector, setFormOpen } from "../redux/rhapsodyReducer";
import { useDispatch } from "react-redux";
import { useMissionMap } from "../templates/answerTemplate";
import { useState } from "react";
import { useReleaseRequestMutation } from "../redux/missionMap";

/**
 *
 * @returns {ReactElement} AskForRelease page
 */
export function AskForRelease({
  token,
  season,
}: {
  token: string;
  season?: boolean;
}) {
  const open = useSelector(formOpenSelector("askForRelease"));
  const mission = useMissionMap(token);
  const [releaseRequest] = useReleaseRequestMutation();
  const [agreed, setAgreed] = useState(false);
  const [workSessionIDs, setWorkSessionIDs] = useState<number[]>([]);
  const [explanation, setExplanation] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const dispatch = useDispatch();
  const Interactors = mission?.Interactors;

  const close = () => {
    setSubmitted(false);
    setWorkSessionIDs([]);
    setExplanation("");
    dispatch(setFormOpen({ formID: "askForRelease", isOpen: false }));
  };

  const submit = async () => {
    await releaseRequest({
      publicToken: mission?.PublicToken,
      releaseWorkSessionIDs: JSON.stringify(workSessionIDs),
      releaseExplanation: explanation,
    }).unwrap();

    setSubmitted(true);
  };

  return (
    <>
      {(!Interactors || Interactors.length === 0) &&
      mission?.EnableRelease &&
      !season ? (
        <Typography
          level="body-xs"
          sx={{
            "&:hover": {
              cursor: "pointer",
              textDecoration: "underline",
            },
          }}
          onClick={() =>
            dispatch(setFormOpen({ isOpen: true, formID: "askForRelease" }))
          }
          startDecorator={<i className="fa-solid fa-person-from-portal"></i>}
        >
          Ask to be released
        </Typography>
      ) : (
        []
      )}
      <Dialog open={open} onClose={close} sx={{ zIndex: 100003 }}>
        <DialogTitle>
          <Typography
            level="title-lg"
            startDecorator={<i className="fa-solid fa-person-from-portal"></i>}
          >
            Release Request
          </Typography>
        </DialogTitle>
        <Divider />
        {submitted ? (
          <>
            <DialogContent
              sx={{
                background: "#eceff1",
                gap: 1,
                display: "flex",
                flexDirection: "column",
                textAlign: "center",
              }}
            >
              <Typography level="h1" color="success">
                <i className="fa-regular fa-circle-check"></i>
              </Typography>
              <Typography level="title-lg">We have your request.</Typography>
              <Typography level="body-md">
                A {mission?.CompanyName} admin will contact you shortly.
              </Typography>
            </DialogContent>
            <DialogActions sx={{ p: 2 }}>
              <Button color="neutral" size="sm" onClick={close}>
                Close
              </Button>
            </DialogActions>
          </>
        ) : (
          <>
            <DialogContent
              sx={{
                background: "#eceff1",
                gap: 1,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Textarea
                variant="outlined"
                autoFocus
                endDecorator={
                  <Typography
                    color={!explanation ? "danger" : "neutral"}
                    level="body-xs"
                  >
                    Required
                  </Typography>
                }
                color={!explanation ? "danger" : "neutral"}
                value={explanation}
                onChange={(e) => setExplanation(e.target.value)}
                startDecorator="Explanation:"
                placeholder="Reason of the release"
              />
              <Divider>
                <i
                  style={{ marginRight: "4px" }}
                  className="fa-solid fa-arrow-down"
                ></i>
                Check the date(s) you wish to be released
                <i
                  style={{ marginLeft: "4px" }}
                  className="fa-solid fa-arrow-down"
                ></i>
              </Divider>
              {mission?.Engagements?.map((e) => {
                return e.Content.filter((e) => e.Playing).map((c) => (
                  <Box key={c.Title}>
                    <Checkbox
                      checked={workSessionIDs.includes(c.WorkSessionID)}
                      onChange={(e) => {
                        const _workSessionIDs = [...workSessionIDs].filter(
                          (e) => e !== c.WorkSessionID
                        );
                        if (e.target.checked) {
                          setWorkSessionIDs([
                            ..._workSessionIDs,
                            c.WorkSessionID,
                          ]);
                        } else {
                          setWorkSessionIDs(_workSessionIDs);
                        }
                      }}
                      label={
                        <span>
                          {c.Title}
                          <br />
                          <Typography level="body-sm">
                            {c.WeekDay} {c.Day} {c.Month} {c.HourRange}
                          </Typography>
                        </span>
                      }
                      key={c.Title}
                    />
                  </Box>
                ));
              })}
            </DialogContent>
            <Divider />
            <DialogActions sx={{ p: 2 }}>
              <Checkbox
                checked={agreed}
                onChange={(e) => setAgreed(e.target.checked)}
                size="sm"
                label={`I understand that this doesn't guarantee the release and I
            will need a confirmation from a ${mission?.CompanyName} admin.`}
              />
              <Button
                disabled={
                  !agreed || workSessionIDs.length === 0 || !explanation
                }
                onClick={submit}
              >
                Submit
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
}
