import { Box } from "@mui/joy";
import { SxProps } from "@mui/joy/styles/types";
import { ReactElement, ReactNode } from "react";

/**
 *
 * @returns {ReactElement} Paper page
 */
export function Paper({ children, sx }: { children: ReactNode; sx?: SxProps }) {
  return (
    <Box
      sx={{
        background: "white",
        borderRadius: "8px",
        overflow: "hidden",
        boxShadow: "0px 2px 6px rgba(0,0,0,0.12)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
        width: "100%",
        gap: 1,
        p: 1,
        position: "relative",
        ...sx,
      }}
    >
      {children}
    </Box>
  );
}
