import { MissionMap_Entity } from "../entities/missionMap";
import { rhapsodyApi } from "./api";

export const missionEndpoints = rhapsodyApi.injectEndpoints({
  endpoints: (build) => ({
    getMissionMap: build.query<MissionMap_Entity, string>({
      query: (token) => `missions/public/${token}`,
      providesTags: ["missions"],
    }),
    releaseRequest: build.mutation<
      void,
      {
        publicToken?: string;
        releaseWorkSessionIDs?: string;
        releaseExplanation?: string;
      }
    >({
      query: (body) => ({
        method: "PUT",
        body: body,
        url: `missions/releaseRequest`,
      }),
    }),
    answer: build.mutation<MissionMap_Entity, any>({
      query: (body) => ({
        method: "PUT",
        body: body,
        url: `missions/mercuryPublic`,
      }),
      invalidatesTags: () => ["missions"],
    }),
  }),
});

export const {
  useGetMissionMapQuery,
  useLazyGetMissionMapQuery,
  useAnswerMutation,
  useReleaseRequestMutation,
} = missionEndpoints;

export default missionEndpoints;
