import { Box, Divider, Typography } from "@mui/joy";
import { Paper } from "../atoms/paper";
import { useMissionMap } from "../templates/answerTemplate";

export function DressCode({ token }: { token: string }) {
  const mission = useMissionMap(token);

  return (
    <Paper>
      <Box>
        <Typography
          startDecorator={<i className="fa-solid fa-clothes-hanger"></i>}
          level="title-lg"
        >
          Dress Code
        </Typography>
        <Typography level="body-xs" sx={{ textAlign: "left", pt: 1 }}>
          {mission?.WageDescription}
        </Typography>
      </Box>
      <Divider />
      <div
        style={{
          textAlign: "left",
          width: `calc(100vw - 32px)`,
          maxWidth: 450,
        }}
        className="ql-editor"
        dangerouslySetInnerHTML={{ __html: mission?.DressCode ?? "" }}
      />
    </Paper>
  );
}
