import { Box, CircularProgress, Typography } from "@mui/joy";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { useGetMissionMapQuery } from "../redux/missionMap";
import { setSelectedInteractorID } from "../redux/rhapsodyReducer";
import { AnswerTemplate } from "../templates/answerTemplate";
import { SeasonInvite } from "./season";

/**
 *
 * @returns {ReactElement} A happy coding page!
 */
export function AnswerRoute() {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const interactorID = searchParams.get("interactorID");
  const version = searchParams.get("version");
  const dispatch = useDispatch();
  const { isLoading, isError } = useGetMissionMapQuery(token ?? "", {
    skip: !token,
  });

  useEffect(() => {
    if (interactorID) {
      dispatch(setSelectedInteractorID(parseInt(interactorID)));
    }
  }, [interactorID]);

  useEffect(() => {
    if (!token) {
      window.location.replace("https://rhapsody.la/");
    }
  }, [token]);

  if (isLoading)
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flex: 1,
        }}
      >
        <CircularProgress />
      </Box>
    );

  if (isError) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flex: 1,
          textAlign: "center",
        }}
      >
        <Helmet>
          <title>Invite Not Found</title>
        </Helmet>
        <Box>
          <img
            height={"75px"}
            src={"https://storage.googleapis.com/rhapsody/logos/logo_main.png"}
          />
          <Typography level="title-lg">Invite not found</Typography>
          <Typography level="title-sm">
            Contact us at support@rhapsody.la if you think it is a bug.
          </Typography>
        </Box>
      </Box>
    );
  }

  if ((token && token[0] === "M") || version === "3") {
    return <SeasonInvite />;
  }

  return <AnswerTemplate token={token ?? ""} />;
}
