import { Box, Divider, Link, Typography } from "@mui/joy";
import { Paper } from "../atoms/paper";
import { Tag } from "../atoms/tag";
import { useMissionMap } from "../templates/answerTemplate";

export function Contacts({ token }: { token: string }) {
  const mission = useMissionMap(token);

  return (
    <Paper>
      <Typography
        startDecorator={<i className="fa-solid fa-user"></i>}
        level="title-lg"
      >
        Contacts
      </Typography>
      {mission?.Contacts?.map((c) => (
        <Tag key={c.Name} sx={{ width: "100%" }}>
          <Box sx={{ width: "100%", display: "flex", gap: 1, p: 1 }}>
            <Box sx={{ textAlign: "left", flex: 1 }}>
              <Typography
                level="body-sm"
                sx={{ textAlign: "left", pt: 1, color: "inherit" }}
              >
                {c.Name}
              </Typography>
              <br />
              <Typography
                level="body-xs"
                sx={{ textAlign: "left", pt: 1, color: "inherit" }}
              >
                {c.Role}
              </Typography>
              <br />
              <Typography level="body-xs" sx={{ textAlign: "left", pt: 1 }}>
                <i>{c.Description}</i>
              </Typography>
            </Box>
            <Divider orientation="vertical" />
            <Box sx={{ textAlign: "right", flex: 1 }}>
              <Link
                level="body-xs"
                href={`mailto:${c.Email}`}
                sx={{ textAlign: "left", color: "inherit" }}
              >
                {c.Email}
              </Link>
              <Link
                level="body-xs"
                href={`tel:${c.Phone}`}
                sx={{ textAlign: "left", color: "inherit" }}
              >
                {c.Phone}
              </Link>
            </Box>
          </Box>
        </Tag>
      ))}
    </Paper>
  );
}
