import { Box, Divider, Link, Typography } from "@mui/joy";
import { useMissionMap } from "../templates/answerTemplate";

export function Footer({ token }: { token: string }) {
  const mission = useMissionMap(token);

  return (
    <Box sx={{ textAlign: "center", color: "white", pb: 8, pt: 2 }}>
      <Box sx={{ display: "flex", gap: 1, justifyContent: "center" }}>
        <Typography
          level="body-md"
          sx={{ color: "inherit", opacity: 0.8 }}
          endDecorator={
            <img
              onClick={() => window.open("https://www.rhapsody.la")}
              src={"https://storage.googleapis.com/rhapsody/Avatars/logo.svg"}
              style={{ height: 25, cursor: "pointer" }}
            />
          }
        >
          <b>
            <i>Powered by</i>
          </b>{" "}
        </Typography>
      </Box>
      <Typography
        level="body-xs"
        sx={{ color: "inherit", opacity: 0.8, mt: 1 }}
      >
        You received this email because of {mission?.CompanyName}
        &apos;s account with Rhapsody.
        <br />
        If you no longer wish to receive Rhapsody calls from{" "}
        {mission?.CompanyName}, please contact them directly.
        <br />
        <Divider sx={{ mt: 1, mb: 1, background: "white", opacity: 0.2 }} />
        Developed with love in Los Angeles, CA
        <br />
        <Link sx={{ color: "inherit" }} href="mailto:contact@rhapsody.la">
          contact@rhapsody.la
        </Link>
        <br />
        <Link sx={{ color: "inherit" }} href="https://www.rhapsody.la">
          www.rhapsody.la
        </Link>
      </Typography>
    </Box>
  );
}
