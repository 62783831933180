import React from "react";
import { Route, Routes } from "react-router-dom";
import { RouterConfig } from "./hooks/config/routerConfig";
import { AnswerRoute } from "./routes/answer";
import NotFoundRoute from "./routes/notFoundRoute";

/**
 *
 * @returns {React.ReactElement} the router
 */
export function AppRouter(): React.ReactElement {
  return (
    <Routes>
      <Route path={RouterConfig.root} element={<AnswerRoute />}></Route>
      <Route path={"*"} element={<NotFoundRoute />} />
    </Routes>
  );
}
