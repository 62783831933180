import { Box, Divider, Typography } from "@mui/joy";
import { Paper } from "../atoms/paper";
import { useMissionMap } from "../templates/answerTemplate";

export function Wage({ token }: { token: string }) {
  const mission = useMissionMap(token);

  return (
    <Paper>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          gap: 2,
          alignItems: "start",
        }}
      >
        <Box>
          <Typography
            startDecorator={<i className="fa-solid fa-wallet"></i>}
            level="title-lg"
          >
            Wage
          </Typography>
          <Typography level="body-xs" sx={{ textAlign: "left", pt: 1 }}>
            {mission?.WageDescription}
          </Typography>
        </Box>
        <Divider orientation="vertical" />
        <Box sx={{ textAlign: "right" }}>
          <Typography level="h4" sx={{ fontWeight: 600, alignItems: "end" }}>
            {mission?.Wage}
          </Typography>
          {mission?.WageUnit ? (
            <Typography level="body-xs">{mission?.WageUnit}</Typography>
          ) : (
            []
          )}
        </Box>
      </Box>
      <Box sx={{ textAlign: "left", width: "100%" }}></Box>
    </Paper>
  );
}
