import { Typography } from "@mui/joy";

/**
 *
 * @returns {ReactElement} Playing page
 */
export function Playing({ playing }: { playing: boolean }) {
  const _playing: any = playing;

  return (
    <Typography
      sx={{
        flexShrink: 0,
        color: playing ? "#00e676" : "#ff1744",
        fontWeight: 800,
      }}
      level="body-xs"
    >
      {playing ? "Playing" : "Not Playing"}
    </Typography>
  );
}
