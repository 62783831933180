import { Avatar, Box, Typography } from "@mui/joy";
import { Playing } from "../atoms/playing";
import { Tag } from "../atoms/tag";
import { Piece } from "../entities/missionMap/missionMap";
import { Memo } from "../organisms/memo";

/**
 *
 * @returns {ReactElement} PieceLineItem page
 */
export function PieceLineItem({
  piece,
  index,
  defaultPieceOnly,
  token,
}: {
  piece: Piece;
  index: number;
  defaultPieceOnly?: boolean;
  token: string;
}) {
  const sectionInfo = [];
  if (piece.SectionName) sectionInfo.push(`Section: ${piece.SectionName}`);
  if (piece.SectionRole) sectionInfo.push(`Role: ${piece.SectionRole}`);
  if (piece.SectionOrder) sectionInfo.push(`Chair# ${piece.SectionOrder}`);
  return (
    <Box sx={{ display: "flex", gap: 0.5, flexDirection: "column" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          gap: 0.5,
          width: "100%",
          textAlign: "left",
        }}
      >
        {!defaultPieceOnly ? <Avatar size="sm">{index}</Avatar> : []}
        {!defaultPieceOnly ? (
          <Box sx={{ flexGrow: 1, ml: 1 }}>
            <Typography level="body-sm" sx={{ color: "black" }}>
              <i>{piece.Title}</i>, {piece.Composer}
            </Typography>
            <Typography level="body-xs">{piece.Description}</Typography>
          </Box>
        ) : (
          []
        )}
        {!defaultPieceOnly ? <Playing playing={piece.Playing} /> : []}
      </Box>
      {piece.Memo ? <Memo token={token} compact memo={piece.Memo} /> : <Box />}
      <Box
        sx={{
          ml: !defaultPieceOnly ? "32px" : "0px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            gap: 0.5,
            ml: 1,
          }}
        >
          {sectionInfo.length ? (
            <Tag startDecorator={<i className="fa-solid fa-chair"></i>}>
              {sectionInfo.join(", ")}
            </Tag>
          ) : (
            []
          )}
          {piece?.Instruments?.length ? (
            <Box sx={{ display: "flex", gap: 0.5 }}>
              <Typography level="body-xs" sx={{ color: "inherit" }}>
                Instrument{piece?.Instruments?.length > 1 ? "s" : ""}:
              </Typography>
              {piece?.Instruments?.map((e) => (
                <Tag key={e}>{e}</Tag>
              ))}
            </Box>
          ) : (
            []
          )}
        </Box>
      </Box>
    </Box>
  );
}
