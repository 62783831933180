import { Box, Button, Typography } from "@mui/joy";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { useDispatch, useSelector } from "react-redux";
import { Paper } from "../atoms/paper";
import { MissionMap_Entity } from "../entities/missionMap";
import { Engagement } from "../entities/missionMap/missionMap";
import {
  selectedTokenSelector,
  setSelectedToken,
} from "../redux/rhapsodyReducer";
import { addToCalendar } from "./schedule";

export function SeasonMissions({
  missions,
}: {
  missions: MissionMap_Entity[];
}) {
  const selectedToken = useSelector(selectedTokenSelector);
  const dispatch = useDispatch();

  const engagements = missions.reduce<Engagement[]>((a, v) => {
    v.Engagements.forEach((e) => {
      e.Content.forEach((c) => {
        a.push(c);
      });
    });
    return a;
  }, []);

  return (
    <Paper sx={{ alignItems: "stretch" }}>
      <Box>
        <Typography level="title-lg">Projects</Typography>
        <Typography level="body-sm">
          {missions.length} Projects, {engagements.length} Services
        </Typography>
        <Button
          startDecorator={<i className="fa-solid fa-calendar-plus"></i>}
          variant="solid"
          color="neutral"
          fullWidth
          onClick={() => addToCalendar(missions)}
        >
          Add to Calendar
        </Button>
        <DataGridPro
          autoHeight
          onRowClick={(r) => dispatch(setSelectedToken(r.row.PublicToken))}
          headerHeight={0}
          sx={{ mt: 1 }}
          hideFooter
          getRowId={(r) => r.PublicToken}
          rows={missions}
          columns={[
            {
              field: "ProjectLogo",
              headerName: "",
              width: 75,
              renderCell: (p) => <img src={p.value} style={{ width: 50 }} />,
            },
            {
              field: "ProjectName",
              headerName: "Name",
              flex: 1,
              renderCell: (p) => (
                <Box sx={{ textAlign: "left" }}>
                  <Typography level="body-md">{p.value}</Typography>
                  <Typography level="body-xs">
                    {p.row.ScheduleDescription}
                  </Typography>
                </Box>
              ),
            },
            {
              field: "token",
              headerName: "",
              width: 20,
              renderCell: (p) => <i className="fa-solid fa-chevron-right"></i>,
            },
          ]}
        />
      </Box>
    </Paper>
  );
}
