import { Box, Typography } from "@mui/joy";
import { ReactElement } from "react";

/**
 *
 * @returns {ReactElement} NotFound route
 */
export default function NotFoundRoute(): ReactElement {
  return (
    <Box sx={{ flex: 1, textAlign: "center", mt: 10 }}>
      <Typography level="title-lg">404 Not Found</Typography>
      <Typography level="title-sm">
        Sorry, we could not find the invite you are looking for.
      </Typography>
    </Box>
  );
}
