import { accountKitApi, accountKitReducer } from "@nerdjs/account-kit";
import { networkMiddleware, networkReducer } from "@nerdjs/nerd-network";
import { notificationReducer } from "@nerdjs/nerd-ui";
import { combineReducers } from "redux";
import thunkMiddleware from "redux-thunk";
import { configureStore } from "@reduxjs/toolkit";
import { mainMiddleware } from "./mainMiddleware";
import { rhapsodyApi } from "./api";
import { unityApi } from "./unity";
import { rhapsodyReducerSlice } from "./rhapsodyReducer";

const rootReducer = combineReducers({
  [rhapsodyApi.reducerPath]: rhapsodyApi.reducer,
  [unityApi.reducerPath]: unityApi.reducer,
  [rhapsodyReducerSlice.name]: rhapsodyReducerSlice.reducer,
  accountKitState: accountKitReducer,
  notification: notificationReducer,

  networkState: networkReducer,
  [accountKitApi.reducerPath]: accountKitApi.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export const store = configureStore({
  reducer: rootReducer,
  middleware: [
    thunkMiddleware,
    rhapsodyApi.middleware,
    mainMiddleware,
    accountKitApi.middleware,
    unityApi.middleware,
    networkMiddleware, // Always add networkMiddleware after your api middleware
  ],
});

export type AppDispatch = typeof store.dispatch;
