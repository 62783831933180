import { Box, Divider, Typography } from "@mui/joy";
import { Paper } from "../atoms/paper";
import { useMissionMap } from "../templates/answerTemplate";

export function ProjectNotes({ token }: { token: string }) {
  const mission = useMissionMap(token);

  return (
    <Paper>
      <Box>
        <Typography level="title-lg">
          Message from {mission?.CompanyName}
        </Typography>
        <Typography level="body-sm">Additionnal information</Typography>
      </Box>
      <Divider />
      <div
        style={{
          textAlign: "left",
          width: `calc(100vw - 32px)`,
          maxWidth: 450,
        }}
        className="ql-editor"
        dangerouslySetInnerHTML={{ __html: mission?.ProjectNotes ?? "" }}
      />
    </Paper>
  );
}
