import { Alert, Box, Typography } from "@mui/joy";
import { Paper } from "../atoms/paper";
import { Memo } from "./memo";
import { useMissionMap } from "../templates/answerTemplate";
import { alpha } from "@mui/material";
import { AskForRelease } from "./askForRelease";

export function Greetings({
  token,
  hideResponse,
}: {
  token: string;
  hideResponse?: boolean;
}) {
  const mission = useMissionMap(token);
  const CurrentStage = mission?.CurrentStage;
  const Interactors = mission?.Interactors;

  return (
    <Paper>
      <Box>
        {mission?.ProjectLogo ? (
          <img
            src={mission?.ProjectLogo}
            style={{ height: 100, borderRadius: "8px" }}
          />
        ) : (
          []
        )}
        <Typography level="title-lg">
          {mission?.EventStatusTitle} {mission?.CompanyName}
        </Typography>
        <Typography level="body-sm">
          Hello {mission?.MusicianName}, {mission?.Greetings}
        </Typography>
      </Box>
      <Typography level="title-lg" sx={{ mt: 1, mb: 1 }}>
        <i>&quot;{mission?.ProjectName}&quot;</i>
      </Typography>
      {mission?.Memo ? <Memo memo={mission?.Memo} token={token} /> : []}
      {!Interactors || (Interactors.length === 0 && !hideResponse) ? (
        <Alert
          size="sm"
          sx={{
            background: CurrentStage?.Color
              ? alpha(CurrentStage.Color, 0.1)
              : "#9e9e9e",
          }}
        >
          <Typography
            startDecorator={
              CurrentStage?.Icon ? (
                <img style={{ height: 14 }} src={CurrentStage?.Icon} />
              ) : undefined
            }
            sx={{ color: CurrentStage?.Color }}
            level="body-sm"
          >
            You have {CurrentStage?.Name}
          </Typography>
        </Alert>
      ) : (
        []
      )}
      <AskForRelease token={token} />
    </Paper>
  );
}
