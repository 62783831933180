import { Box, Typography } from "@mui/joy";
import { SxProps } from "@mui/joy/styles/types";
import { ReactElement, ReactNode } from "react";

/**
 *
 * @returns {ReactElement} Paper page
 */
export function Tag({
  children,
  sx,
  startDecorator,
}: {
  children: ReactNode;
  sx?: SxProps;
  startDecorator?: ReactElement;
}) {
  return (
    <Box
      sx={{
        background: "#9e9e9e22",
        borderRadius: "8px",
        border: "solid 1px #9e9e9e33",
        pl: 0.5,
        pr: 0.5,
        ...sx,
      }}
    >
      <Typography
        sx={{ color: "inherit" }}
        startDecorator={startDecorator}
        level="body-xs"
      >
        {children}
      </Typography>
    </Box>
  );
}
