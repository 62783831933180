import { Box, Divider, Typography } from "@mui/joy";
import { Paper } from "../atoms/paper";
import { PieceLineItem } from "../molecules/pieceLineItem";
import { useMissionMap } from "../templates/answerTemplate";

export function Repertoire({ token }: { token: string }) {
  const mission = useMissionMap(token);

  const defaultPieceOnly =
    mission?.Pieces?.length === 1 &&
    !mission?.Pieces[0]?.Title &&
    !mission?.Pieces[0]?.Description;

  return (
    <Paper>
      <Box>
        <Typography level="title-lg">
          {defaultPieceOnly ? "Seating Assignment" : "Repertoire"}
        </Typography>
      </Box>
      <Box
        sx={{ display: "flex", flexDirection: "column", gap: 1, width: "100%" }}
      >
        {mission?.Pieces?.map((e, i) => (
          <>
            <Divider />
            <PieceLineItem
              defaultPieceOnly={defaultPieceOnly}
              index={i + 1}
              key={e.Title}
              piece={e}
              token={token}
            />
          </>
        ))}
      </Box>
    </Paper>
  );
}
